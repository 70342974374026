@import "../master";

.notification-card-parent {
    background: white;
    box-shadow: none;
    overflow-y: scroll;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 30px 16px 16px;
}

.mobile-feed-title{
    text-align:center;
    margin:0 auto;
    color:#fff;
    font-size: 1.3em;
    font-weight: bold;
    letter-spacing: 1px;
}

.notification-card-timestamp {
    font-family: $common-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    color: #FFFFFF;
    opacity: 0.75;
    z-index: 10;
}

.notification-card-description {
    font-family: $common-font;
    padding: 8px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}

.notification-circle {
    background: #E15235;
    border-radius: 50px;
    height: 8px;
    width: 8px;
}

.mobile-parent {
    display: flex;
    flex-direction: column;
}

.notification-card-title {
    display: flex;
    align-items: center;
    font-family: $common-font;
    padding: 8px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}

.notification-cover-img{
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 275px;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-bottom: 10px;
    cursor: pointer;
}

.overflow-feeds{
    overflow-y: scroll;
    height: 100%;
}

@media screen and (max-width: 768px) {

.notification-card-parent {
    min-height: 80vh;
}
}