.chart-info-parent{
    width: 100%;
    max-width: 100vw;
    background: white;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.25);
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   box-sizing: border-box;
}

.chart-info-number{
    background:rgba(177, 177, 177, 0.25);
    display: grid; 
    grid-template-columns: 1fr 1fr ;
    justify-items: center;
    width: 100%;
    box-sizing: border-box;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.winner-text{
    font-size: 14px;
    font-weight: 600;
}

.score-text{
    font-size: 36px;
    font-weight: 600;
    transform: translate(0px, -7px);
    padding-left: 1em;
}

.hori{
    display: flex;
}

.updates-parent{
    width: -webkit-fill-available;
    max-width: 35%;
    height: 100%;
}

.v-gap-m{
    margin-top: 2em;
    margin-bottom: 2em;
}