@import "../master";

.state-info-card-parent{
    
    background: white;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 10;
   box-sizing: border-box;
}

.state-info-card-top-bg{
    background:#464646;
    width:100%;
    text-align: center ; 
    border-top-left-radius:10px;
    border-top-right-radius:10px;
}
.stateinfo_party{
    margin:0.5em 0;
    color:#33333380;
    font-size: 0.85em;
}
.state-info-card-title{
    padding:10px;
    font-size:20px;
    font-weight:700;
    color:white;
}

.state-info-percentage-sub-title{
    font-family: $common-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}

.state-info-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
    gap: 1em;
    padding-bottom: 196px;
}

.candidate-score-bar{
    color: black;
    font-weight: 700;
    padding: 8px;
    border-radius: 16px;
    height: 5px;
    box-sizing: border-box;
}

.state-info-candidate-block{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    background: rgb(223, 223, 223);
    align-items: center;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.state-info-mini-title{
    font-weight: bold;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}
.non-official-bar{
    line-height:1.5;
    font-size: 14px;
    text-align:center; 
    color:#fff;
    background:#454645;
    display: block;
    width:100%;
}