.table-info-parent{
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.top-bottom-border{
  border-top: 1px solid #33333360;
  // border-bottom: 1px solid #33333350;
}

@keyframes rotate-arrow {
  0% {
    transform: rotateZ(0deg)
  }
  100% {
    transform: rotateZ(90deg);
  }
}



@keyframes reverse-rotate {
  0% {
    transform: rotateZ(90deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.full-table-header {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.table-header {
  flex-direction: column;
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-top: 10px;
  flex: 1
}

.pad {
  padding: 10px;

}

.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  padding: "0.8em";
 
}



.number-data {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

.table-info-number-data{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
  text-align: center;
  // border-top: 1px solid #DFDFDF;
  width: 30%;
  height: 48px;
  flex:1
}


.table-info-header-text{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  width: 30% ;
  height: 48px ;
  flex:1
}

.poll-tag-parent{
  width: 40%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-top: 1px solid #DFDFDF;
}

.table-info-spacer {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}