// path{
//     fill:none;
//     stroke:black;
// }
path:hover {
    // fill: red;
    // stroke: green;
    opacity: 0.5;
    cursor: pointer;
}

.map-parent {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(50%, 400px));
    padding: 0;
}

.center-map {
    display: flex;
    justify-content: center;
}
