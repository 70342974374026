
.circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 16px;
    line-height: 2;
    color: white;
    text-align: center;
    font-weight: 700;
    
  }

  .full-table-header{
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .table-header{
    flex-direction: column;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top:10px;
    flex:1
  }
  
  .pad{
    padding:10px;
   
  }

  .header-text{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5); 
  }
  
  .number-data{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);
    width: 50%;
    text-align: center;
  }

  .parliament-data-info{
    display: flex;
    flex-direction: row;
    background: grey;
  }

  .table-info{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .table-info-parent{
    display: flex;
    flex-direction: column;
  }

  .table-block{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid #DFDFDF;
    border-right: 1px solid rgb(223, 223, 223);
    height: 42px;
  }

  .table-block-parent{
    display: block;
    position: relative;
  }

  .blocker{
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 12px;
    backdrop-filter: blur(2px);
  }