@import "../master";


.scrollbar
{
	margin-left: 30px;
	float: left;
	height: 300px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}

 #style-2::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.15);
     border-radius: 10px;
     background-color: #F5F5F540;
 }
 
 #style-2::-webkit-scrollbar
 {
     width: 8px;
     height: 2px;
     background-color: #F5F5F580;
 }
 
 #style-2::-webkit-scrollbar-thumb
 {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.15);
     background-color: $primary-color-light;
 }
 
.no-scrollbar{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

$cover-image-height: 20vh;

html {
    scroll-behavior: smooth;
}

.parent {
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

}

.full-width {
    width: 100vw;
}

.center-v {
    display: flex;
    align-items: center;
}

.center-h {
    display: flex;
    justify-content: center;
}

.f-v {
    flex-direction: column;
}

.parent-center {
    max-width: 350px;
    width: 100%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.max-width {
    width: 100%;
}
.map-parent {
    justify-content: center;
}
@media screen and (max-width: 768px) {
    .main-page-parent {
        display: grid;
        grid-gap: 16px;
        padding-bottom: 256px;
        grid-auto-flow: column;
        justify-content: center;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-auto-rows: minmax(min-content, max-content);
    }
    .main-page-cover-image .center-all {
        height: 88%;
    }

    .main-page-notification {
        max-height: 80vh;
    }

@keyframes notification-height{
    0%{
        min-height: 0vh;
        max-height: 0vh;
    }

    100%{
        min-height: 80vh;
        max-height: 80vh;
    }
}
}

@media screen and (min-width: 769px) {
    .main-page-video {
        width: 78%;
        margin: 0 auto;
    }
}

@media screen and (min-width: 481px) {
    .main-page-nav-buttons{
        justify-content: center;
    }
}
.home-page-button-parent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    justify-content: center;
}
.quick-navigate-parent {
    margin: 1em 0;
}
hr.solid {
    border-top: px solid #000000;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    margin: 0;
}

table {
    border-collapse: collapse;
    border-radius: 8px;
    width: 100%;
    // border-spacing: 0;

}

td {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
    font-family: $common-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: black;
}

th {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
    font-family: $common-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
}
tr:nth-child(even) {
    background-color: rgba(70, 70, 70, 0.1);
    border-radius: 8px;
}
tr:nth-child(odd) {
    border-radius: 8px;
}

.bottom {
    padding-left: 1.5em;
    padding-right: 1.5em;
    box-sizing: border-box;
    height: calc(100vh - $cover-image-height - 49.5px);
    overflow: hidden;
}
@media screen and (max-width: 768px) {
    .bottom {
        padding-left: 1em;
        padding-right: 1em;
        // height: calc(100vh - $cover-image-height - 49.5px);
    }

    .nav-button {
        font-size: 0.8em;
        padding: 8px !important;
    }
}

.main-page-title {
    font-family: $common-font;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    justify-content: center;
    color: #000000;
    padding: 12px;
}
table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;

}
table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}
table tr:first-child td:first-child {
    border-top-left-radius: 100px;
}

.absolute {
    position: absolute;
    top: 0;
    left: 0;
}

.media-button {
    width: 32px;
    padding: 4px;
    cursor: pointer;
}

.center-all {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.top-left-all {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.bottom-right-all {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;
}

.main-page-cover-image {
    height: $cover-image-height;
    width: 100%;
    // scale: 1.01;
    object-fit: cover;
    position: relative;
    display: flex;
    border-bottom: 2px solid red;
}

.main-page-notification {
    position: fixed;
    bottom: 0;
    max-height: 80vh;
    overflow: scroll;
    z-index: 150;
}

.main-page-more-details {
    font-family: $common-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 2em;
    color: rgba(0, 0, 0, 0.25);

    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

.main-page-logo {
    margin-top: 8px;
}

.nav-button-after {
    background-color: #EE4139;
    color: white;
    overflow-x: hidden;
    font-weight: 700;
    transition: 0.2s ease;
    text-decoration-line: underline;
    text-underline-offset: 10px;
    text-decoration-color: black;
    text-decoration-thickness: 2px;
}

.main-page-nav-buttons {
    color: black;
    overflow-x: scroll;
    width:100%;
    display: flex;
    // position: absolute;
    top: $cover-image-height;
    overflow-y: hidden;
}

.main-page-parent {
    display: grid;
    grid-gap: 16px;
    padding-bottom: 128px;
    grid-auto-flow: column;
    justify-content: center;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
}

.main-page-mobile-parent {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.main-page-chart-info-mobile {
    width: calc(100vw - 2em);
}

.main-page-chart-info-desktop {
    // width: 600px;
    width: calc(100% - 0px);
}

.flex-h {
    display: flex;
    gap: 1em;
}

.updates {
    background: white;
    border: 1px solid rgb(191, 191, 191);
    box-shadow: none;
    overflow-y: scroll;
    max-height: 620px;
    /* max-width: 480px; */
    /* min-width: 300px; */
    width: 100%;
    box-sizing: border-box;
}

.max-constraint {
    max-width: 1536px;
    // height: calc(100vh - $cover-image-height);
    overflow: scroll;
}

.left-constraint {
    max-width: 1050px;
    padding-right: 1em;
    box-sizing: border-box;
}

.center-flex {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-page-state-seat {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
}

.state-button {
    display: flex;
    flex-direction: row;
    padding: 6px;
    border-radius: 8px;
    justify-content: center;
    box-sizing: border-box;
}

.nav-button {
    cursor: pointer;
    padding: 8px 16px 16px;
    z-index: 10;
}

.nav-button:hover {
    background-color: #EE4139;
    color: white;
    overflow-x: hidden;
    width: 200;
    height: 100;
    font-weight: 700;
    transition: 0.2s ease;
    text-decoration-line: underline;
    text-underline-offset: 10px;
    text-decoration-color: black;
    text-decoration-thickness: 2px;
}

.rel {
    position: relative;
}

.nav-button-overlay {
    padding: 8px;
    box-sizing: border-box;
    border-bottom: 3px solid;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    border-bottom: 5px;
}

.ads-parent {
    display: flex;
    justify-content: center;
    margin: 1em auto;
}

.center-h {
    display: flex;
    justify-content: center;
}

.text-c {
    text-align: center;
}

.fader {
    height: 24px;
    width: 100%;
    background: linear-gradient(to bottom, rgb(255, 255, 255, 255), rgba(255, 255, 255, 0));
    position: absolute;
    top: 0;
    left: 0;
}

.result-announced {
    color: red;
    opacity: 0.5;
    width: 100%;
    text-align: end;
    font-weight: 700;
    padding-right: 1em;
    box-sizing: border-box;
}

.center-map {
    display: flex;
    justify-content: center;
}

.bottom-right-shortcut {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.absolute-bottom {
    position: absolute;
    bottom: 0;
}

.search-bar {
    left: 5%;
    width: 90%;
    z-index: 5;
    display: flex;
}
.find-state{
    display: inline-flex;
    background: rgba(45, 102, 178, 0.5019607843);
    color: #fff;
    cursor: pointer;
    height: -moz-fit-content;
    height: 3em;
    line-height: 3em;
    width: 4em;
    font-size: 1.3em;
    text-align: center;
    margin: 0 0.5em auto 0;
    justify-content: center;
    border-radius: 30px;
}
.find-state:hover{
    background-color: #2d66b2;
}
.bottom-bar {
    height: 10vh;
    width: 100%;
    padding: 0 0.5em;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottom-dark-fade {
    background-image: linear-gradient(to bottom, #00000000, #000000FF);
}

@keyframes fade-in{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}